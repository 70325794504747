<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>Please Sign In</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <button mat-raised-button (click)="login()" type="button">
            <img src="/assets/images/whmcs.png" alt="WHMCS Logo">
        </button>
        <small>© 2021–{{ this.getCurrentYear() }}</small>
    </mat-card-content>
</mat-card>
